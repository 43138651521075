import React from "react"
import { graphql } from "gatsby"
import { injectIntl } from "gatsby-plugin-intl"
import NavBar from "../components/navbar"
import SEO from "../components/SEO/index"
import { Section } from "../components/CompoundComponents/Section"
import { Columns, Column } from "../components/CompoundComponents/Columns"

const PrivacyPage = ({ data }) => {
  return (
    <>
      <SEO
        title="Ниҳол саломатлик сиҳатгоҳи"
        description="Сўлим табиат. Шифобахш сув. Сифатли хизмат. Ниҳол саломатлик сиҳатгоҳи Тоғ тизмалари ён бағрида, Норин дарёси ўзанидаги Катта Фарғона канали билан туташ жойлашган."
      />
      <NavBar />
      <Section medium>
        <Columns>
          <Column is8 offset2>
            <div className="content">
              <h2>Политика конфиденциальности сайта </h2>
              <p>
                Настоящая Политика устанавливает особенности сбора и обработки
                Обществом с ограниченной ответственностью «Нихол саломатлик
                сихатгохи» (далее – Компания) данных физических лиц –
                посетителей сайта https://www.nihol-sihatgoh.uz/ (далее – Сайт).
              </p>
              <p>
                Настоящая Политика применяется исключительно к информации,
                которая была получена Компанией в результате использования и/или
                посещения Сайта физическими лицами (далее – Посетители).
              </p>
              <p>
                При посещении Сайта, в частности раздела «Обратная связь» или
                любого другого раздела Сайта, подразумевающего получение
                обработку Компанией персональных данных Посетителей, Посетитель
                в полном объеме принимает условия настоящей Политики и выражает
                свое добровольное определенное согласие на обработку
                персональных данных способом и в целях как это описано в
                настоящей Политике. Если Посетитель не согласен с настоящей
                Политикой, Компания просит отказаться Посетителя от
                использования соответствующего раздела Сайта.
              </p>
              <p>
                Компания вправе в одностороннем порядке вносить изменения в
                настоящую Политику, информируя об этом Посетителей путем
                опубликования указанных изменений на Сайте.
              </p>
              <h3>
                1. Получаемая и используемая информация Посетителей, а также
                цели ее использования.
              </h3>
              <p>
                В рамках настоящей Политики конфиденциальности под информацией
                Посетителя понимается: персональная информация, которую
                Посетитель самостоятельно предоставляет Компании при
                использовании соответствующих разделов Сайта (ФИО, номер
                телефона и т.д.), а также автоматически передаваемые данные в
                процессе посещения Сайта, в том числе, но не ограничиваясь:
                IP-адрес, сведения о мобильном устройстве, с которого
                осуществляется доступ к Сайту и т.д.
              </p>
              <p>
                При посещении Сайта может быть запрошена и получена следующая
                информация:
              </p>
              <p>
                <span className="has-text-weight-bold">
                  Информация о Посетителе.
                </span>{" "}
                При создании посещении соответствующего раздела Сайта, Компанией
                запрашивается информация о Посетителе, например, ФИО, адрес
                электронной почты, номер телефона, Посетителя. Компания
                оставляет за собой право запросить дополнительную информацию.
              </p>
              <p>
                <span className="has-text-weight-bold">
                  Информация об устройстве.
                </span>{" "}
                Компания оставляет за собой право по сбору данных об устройствах
                Посетителей включая, но не ограничиваясь: IP адреса устройства,
                модель мобильного устройства, версия операционной системы,
                уникальные идентификаторы устройства, а также данные о мобильной
                сети и номер мобильного телефона.
              </p>
              <p>
                Информация, полученная Компанией от Посетителя, может быть
                использована Компанией для предоставления Посетителю
                персонализированных сервисов, в статистических и
                исследовательских целях, а также для улучшения Сайта и связанных
                с ним сервисов.
              </p>
              <p>
                При использовании информации Посетителей Сайта руководствуется
                настоящей Политикой конфиденциальности, а также действующим
                законодательством Республики Узбекистан.
              </p>
              <h3>2. Предоставление информации Посетителей третьим лицам</h3>
              <p>
                Компания гарантирует, что не передает полученную от Посетителей
                информацию третьим лицам за исключением случаев, предусмотренных
                действующим законодательством Республики Узбекистан или
                вытекающих из технических особенностей сервисов, предоставляемых
                Посетителям Сайта. При этом лица, правомерно получающие
                информацию о Посетителях Сайта в силу технических особенностей,
                обязуются соблюдать настоящую Политику, а также нормы
                действующего законодательства Республики Узбекистан.
              </p>
              <h3>
                3. Меры безопасности, используемые для сохранения
                конфиденциальности информации
              </h3>
              <p>
                Компания гарантирует, что Компанией предпринимаются все
                возможные меры для обеспечения безопасности и защиты информации
                Посетителей от несанкционированных попыток доступа, изменения,
                раскрытия или уничтожения, а также иных видов ненадлежащего
                использования.{" "}
              </p>
            </div>
          </Column>
        </Columns>
      </Section>
    </>
  )
}

export default injectIntl(PrivacyPage)
