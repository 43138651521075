import React from "react"
import { injectIntl, Link } from "gatsby-plugin-intl"
import { useToggle } from "../hooks/use-toggle"
import Language from "../components/language"

const NavBar = ({ intl }) => {
  const [val, toggleMenu] = useToggle(false)

  return (
    <>
      <nav
        className="navbar is-shadow"
        role="navigation"
        aria-label="main navigation"
      >
        <div className="container">
          <div className="navbar-brand">
            <Link
              className="navbar-item is-uppercase has-text-weight-900 is-size-5 has-text-primary mr-6"
              to="/"
            >
              nihol
            </Link>
            <a
              role="button"
              className={
                val ? "navbar-burger burger is-active" : "navbar-burger burger"
              }
              aria-label="menu"
              aria-expanded="false"
              data-target="navbarBasic"
              onClick={toggleMenu}
            >
              <span aria-hidden="true"></span>
              <span aria-hidden="true"></span>
              <span aria-hidden="true"></span>
            </a>
          </div>

          <div className={val ? "navbar-menu is-active" : "navbar-menu"}>
            <div className="navbar-start is-uppercase is-size-7">
              <div className="navbar-item has-dropdown is-hoverable">
                <a className="navbar-link has-text-weight-bold">
                  {intl.formatMessage({ id: "header.nav.item-2" })}
                </a>
                <div className="navbar-dropdown is-size-7 has-text-weight-bold">
                  <Link to="/cottage" className="navbar-item">
                    {intl.formatMessage({
                      id: "header.dropdown_item_2.item-1",
                    })}
                  </Link>
                  <Link to="/junior-suite" className="navbar-item">
                    {intl.formatMessage({
                      id: "header.dropdown_item_2.item-3",
                    })}
                  </Link>
                  <Link to="/luxury" className="navbar-item">
                    {intl.formatMessage({
                      id: "header.dropdown_item_2.item-4",
                    })}
                  </Link>
                </div>
              </div>
              <div className="navbar-item has-dropdown is-hoverable">
                <a className="navbar-link has-text-weight-bold">
                  {intl.formatMessage({ id: "header.nav.item-3" })}
                </a>
                <div className="navbar-dropdown is-size-7 has-text-weight-bold">
                  <Link to="/diagnostics" className="navbar-item">
                    {intl.formatMessage({
                      id: "header.dropdown_item_3.item-1",
                    })}
                  </Link>
                  <Link to="/physiotherapy" className="navbar-item">
                    {intl.formatMessage({
                      id: "header.dropdown_item_3.item-2",
                    })}
                  </Link>
                  <Link to="/massage" className="navbar-item">
                    {intl.formatMessage({
                      id: "header.dropdown_item_3.item-3",
                    })}
                  </Link>
                  <Link to="/pool" className="navbar-item">
                    {intl.formatMessage({
                      id: "header.dropdown_item_3.item-4",
                    })}
                  </Link>
                  <Link to="/phytosauna" className="navbar-item">
                    {intl.formatMessage({
                      id: "header.dropdown_item_3.item-5",
                    })}
                  </Link>
                  <Link to="/cafe" className="navbar-item">
                    {intl.formatMessage({
                      id: "header.dropdown_item_3.item-6",
                    })}
                  </Link>
                  <Link to="/chayhona" className="navbar-item">
                    {intl.formatMessage({
                      id: "header.dropdown_item_3.item-7",
                    })}
                  </Link>
                  <Link to="/fitness" className="navbar-item">
                    {intl.formatMessage({
                      id: "header.dropdown_item_3.item-8",
                    })}
                  </Link>
                  <Link to="/beauty" className="navbar-item">
                    {intl.formatMessage({
                      id: "header.dropdown_item_3.item-9",
                    })}
                  </Link>
                </div>
              </div>
              <Link
                to="/contact"
                className="navbar-item is-size-7 has-text-weight-bold"
                activeClassName="is-active"
              >
                {intl.formatMessage({
                  id: `header.nav.item-4`,
                })}
              </Link>
            </div>
            <div className="navbar-end is-uppercase">
              <div className="navbar-item">
                <div className="field is-grouped is-justify-content-center">
                  <p className="control">
                    <Link
                      className="button is-rounded is-size-7 is-outlined is-primary has-text-weight-bold is-normal has-family-pt-sans"
                      to="/booking"
                    >
                      {intl.formatMessage({
                        id: `header.nav.item-5`,
                      })}
                    </Link>
                  </p>
                </div>
              </div>
              <div className="navbar-item has-text-centered">
                <Language />
              </div>
            </div>
          </div>
        </div>
      </nav>
    </>
  )
}

export default injectIntl(NavBar)
