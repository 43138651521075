import React from "react"
import { IntlContextConsumer, changeLocale } from "gatsby-plugin-intl"
import classNames from "classnames"

const languageName = {
  uz: "UZ",
  ru: "RU",
}

const Language = () => {
  return (
    <IntlContextConsumer>
      {({ languages, language: currentLocale }) =>
        languages.map(language => (
          <button
            key={language}
            onClick={() => changeLocale(language)}
            className={classNames(
              "button is-outlined is-size-7 is-rounded has-text-weight-bold is-uppercase mr-05"
            )}
          >
            {languageName[language]}
          </button>
        ))
      }
    </IntlContextConsumer>
  )
}

export default Language
